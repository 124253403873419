<template>
  <v-card v-if="$can('category_list', 'category')">
    <v-card-title
      v-if="$can('category_save', 'category')"
      class="d-flex align-center flex-wrap pb-0"
    >
      <div class="d-flex align-center pb-5">
        <!-- create invoice -->
        <v-btn
          color="primary"
          class="me-3"
          @click="editItem()"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>新增</span>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :hide-default-footer="true"
        :items-per-page="pages.itemsPerPage"
        :server-items-length="pages.serverItemsLength"
        :headers="getHeader()"
        no-data-text="无数据"
        :items="categorys"
      >
        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <v-tooltip
            v-if="$can('category_edit', 'category')"
            top
          >
            <template #activator="{ on, attrs }">
              <v-icon
                small
                class="me-2"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <span>编辑</span>
          </v-tooltip>
          <v-tooltip
            v-if="$can('category_delete', 'category')"
            top
          >
            <template #activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                @click="openDeleteDialog(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <span>删除</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>

    <v-navigation-drawer
      v-model="editDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 750 : '100%'"
      app
    >
      <v-card height="100%">
        <div class="drawer-header d-flex align-center">
          <span class="font-weight-semibold text-base text-h6">{{ editedItem.id ? '编辑' : '添加' }}分类</span>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-5"
            color="primary"
            :disabled="commitFlag"
            :loading="commitFlag"
            @click="handleFormSubmit()"
          >
            保存
          </v-btn>
          <v-btn
            icon
            small
            @click="editDialog = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-card-text
          style="background-color: #F4F5FA; height: 90%; padding-top: 20px;"
          class="overflow-auto"
        >
          <v-card>
            <v-form
              ref="editForm"
              lazy-validation
              @submit.prevent="handleFormSubmit"
            >
              <v-card-text class="pa-3">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="name"><span style="color: red">*</span>分类名称：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        id="name"
                        v-model="editedItem.name"
                        :rules="[rules.required]"
                        required
                        counter="25"
                        label="名称"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="code"><span style="color: red">*</span>分类编码：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        id="code"
                        v-model="editedItem.code"
                        :rules="[rules.required]"
                        required
                        label="编码"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="sort">排序：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        id="sort"
                        v-model="editedItem.sort"
                        label="排序"
                        type="Number"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>

    <v-dialog
      v-model="deleteDialog"
      width="300"
    >
      <v-card>
        <v-card-text> 是否删除 {{ this.deleteData.name }} ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItem(id)"
          >
            确认删除
          </v-btn>
          <v-btn
            color="normal"
            text
            @click="deleteDialog = false"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiClose,
} from '@mdi/js'
import { required } from '@core/utils/validation'
import CategoryApi from '@/api/system/resource/category'

export default {
  name: 'ResourceCategory',
  components: {},
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
      },
      pages: {
        itemsPerPage: -1,
        serverItemsLength: 0,
      },
      rules: {
        required,
      },
      categorys: [],
      editDialog: false,
      deleteDialog: false,
      editedItem: {},
      defaultItem: {
        sort: 1,
        name: '',
        code: '',
      },
      loadings: {
        submitLoading: false,
      },
      deleteData: {},
      commitFlag: false,
    }
  },
  mounted() {
    if (this.$can('category_list', 'category')) {
      this.loadData()
    }
  },
  methods: {
    getHeader() {
      const baseHeader = [
        { text: '编号', sortable: false, value: 'id' },
        { text: '名称', value: 'name' },
        { text: '模块编码', value: 'code' },

        // { text: '创建时间', value: 'createdTime' },
        { text: '排序', value: 'sort' },
      ]
      if (this.$can('category_delete', 'category')
          || this.$can('category_edit', 'category')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    async editItem(item) {
      if (item) {
        this.editedItem = item
      } else {
        this.$nextTick(() => {
          this.editedItem = this.defaultItem
          this.initEditedItem()
        })
      }
      this.commitFlag = false
      this.editDialog = true
    },
    deleteItem() {
      CategoryApi.delete(this.deleteData.id).then(res => {
        this.$toast.success('删除成功！')
        this.deleteDialog = false
        this.loadData()
      })
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return
      if (this.commitFlag) return
      this.commitFlag = true
      CategoryApi.save(this.editedItem).then(res => {
        this.$toast.success('保存成功')
        this.commitFlag = false
        this.closeDialog()
        this.loadData()
      })
    },
    loadData() {
      CategoryApi.list().then(res => {
        this.categorys = res.data.data
        this.pages.serverItemsLength = this.categorys.length
      })
    },
    closeDialog() {
      this.$refs.editForm.resetValidation()
      this.editedItem = this.defaultItem
      this.editDialog = false
    },
    initEditedItem() {
      this.defaultItem.id = ''
      this.editedItem.name = ''
      this.editedItem.code = ''
      this.editedItem.sort = 1
      this.$refs.editForm.resetValidation()
    },
    openDeleteDialog(data) {
      this.deleteData = data
      this.deleteDialog = true
    },
  },
}
</script>

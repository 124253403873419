import axios from '@axios'
import qs from 'qs'

const prefixPath = '/api/system/resource/category'
export default {

  // get(id) {
  //   return axios.get(`${prefixPath}/${id}`)
  // },

  save(data) {
    return axios.post(`${prefixPath}/saveOrUpdate`, data)
  },

  list(resourceId) {
    return axios.get(`${prefixPath}/getAll`, {
      params: {
        resourceId,
      },
    })
  },

  delete(id) {
    return axios.post(`${prefixPath}/delete`, qs.stringify({
      id,
    }))
  },

}
